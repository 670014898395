/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import YotpoStars from 'src/components/YotpoStars'
import theme from 'src/utils/styles/theme'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Analytics from 'src/utils/Analytics'
import PropTypes from 'prop-types'
import { FRAME_PRODUCT_DETAILS } from './constants'
import { BuyNowLink } from '../Hero/styles'
import frame2hero from '../../videos/frame-2-hero.mp4'
import frame2heroMobile from '../../videos/frame-2-hero-mobile.mp4'

import {
  Container,
  Header,
  Subheading,
  TextBox,
  Row,
  TextContainer,
  TextPosition,
  HeroVideoContainer,
  CtaContainer,
  HeroMobileImageContainer,
  HeroVideo,
} from './styles'

import { Intro } from '../CalMaxHero/styles'
import {
  CAL_BUY_LINK,
  IN_AU,
  IN_CA,
  IN_UK,
  IS_FOREIGN_COUNTRY,
  LOCALIZATION,
} from '../../utils/constants'

export default function HomepageHero({ singleSlide, slideContent, noDualCTA, isFrame2 }) {
  const {
    frameBackground,
    frameCalBackground,
    frameBackgroundMobile,
    frameCalBackgroundMobile,
    headings,
  } = useStaticQuery(graphql`
    query HomepageHeroQuery {
      frameCalBackground: file(relativePath: { eq: "homepage/frame-cal-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frameBackground: file(relativePath: { eq: "homepage/baby-dog-in-wood-frame.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frameCalBackgroundMobile: file(
        relativePath: { eq: "homepage/frame-cal-background-mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frameBackgroundMobile: file(
        relativePath: { eq: "homepage/baby-dog-in-wood-frame-mobile.webp" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      headings: allContentfulHomepageHero {
        edges {
          node {
            en_heading
            en_ca_heading
            en_au_heading
            en_gb_heading
            en_eu_heading
            ie_heading
          }
        }
      }
    }
  `)

  const { t } = useTranslation('common')

  const DESKTOP_IMAGES = {
    Home: frameCalBackground,
    Frame: frameBackground,
    'Frame 2': frameBackground,
  }
  const MOBILE_IMAGES = {
    Home: frameCalBackgroundMobile,
    Frame: frameBackgroundMobile,
    'Frame 2': frameBackgroundMobile,
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 5000,
          adaptiveHeight: true,
          arrows: false,
          lazyLoad: false,
        },
      },
    ],
  }
  const filteredSlides = singleSlide ? slideContent : FRAME_PRODUCT_DETAILS
  const hasDualCta = (!noDualCTA && !IS_FOREIGN_COUNTRY) || IN_AU || IN_CA || IN_UK

  const headingsData = headings.edges[0].node
  const country = `${LOCALIZATION.replace(/-/g, '_')}_heading`
  const heading = headingsData?.[country] === 'NONE' ? '' : headingsData[country]

  return (
    <Container>
      <Slider {...settings}>
        {filteredSlides.map((slide, i) => (
          <Row key={i}>
            <HeroVideoContainer>
              {slide.name === 'Frame 2' && !singleSlide ? (
                <HeroVideo playsInline autoPlay muted loop controls={false}>
                  <source src={frame2hero} type="video/mp4" />
                </HeroVideo>
              ) : (
                <GatsbyImage
                  image={DESKTOP_IMAGES[slide.name].childImageSharp.gatsbyImageData}
                  alt={`Skylight ${slide.name}`}
                  style={{ maxHeight: '700px' }}
                />
              )}
            </HeroVideoContainer>
            <HeroMobileImageContainer>
              {slide.name === 'Frame 2' && !singleSlide ? (
                <HeroVideo playsInline autoPlay muted loop controls={false}>
                  <source src={frame2heroMobile} type="video/mp4" />
                </HeroVideo>
              ) : (
                <GatsbyImage
                  image={MOBILE_IMAGES[slide.name].childImageSharp.gatsbyImageData}
                  alt={`Skylight ${slide.name}`}
                />
              )}
            </HeroMobileImageContainer>
            <TextPosition id="text">
              <TextContainer singleSlide={singleSlide}>
                <YotpoStars color={theme.orange} textcolor="#444444" productId={slide.productId} />
                {singleSlide && <Intro>{t(slide.preheading)}</Intro>}
                <TextBox>
                  <Header>{isFrame2 ? t(slide.heading) : heading}</Header>
                  <Subheading>{t(slide.subheading)}</Subheading>
                </TextBox>
                <CtaContainer>
                  <BuyNowLink onClick={() => Analytics.track(slide.analyticsEvent)} to={slide.link}>
                    {t(slide.ctaText)}
                  </BuyNowLink>
                  {hasDualCta && (
                    <BuyNowLink
                      onClick={() =>
                        Analytics.track('Clicked Buy Now Calendar Hero on Homepage CTA')
                      }
                      to={CAL_BUY_LINK}
                    >
                      {t(slide.ctaText2)}
                    </BuyNowLink>
                  )}
                </CtaContainer>
              </TextContainer>
            </TextPosition>
          </Row>
        ))}
      </Slider>
    </Container>
  )
}

HomepageHero.propTypes = {
  singleSlide: PropTypes.bool,
  noDualCTA: PropTypes.bool,
  slideContent: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      preheading: PropTypes.string,
      heading: PropTypes.string,
      subheading: PropTypes.string,
      link: PropTypes.string,
      link2: PropTypes.string,
      ctaText: PropTypes.string,
      ctaText2: PropTypes.string,
      productId: PropTypes.string,
      analyticsEvent: PropTypes.string,
    })
  ),
  isFrame2: PropTypes.bool,
}
